.vlastnosti-bubble{
    background-image: url('./../img/icons/bublina.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.vlastnosti-povolani_filtered{
    background-color: $color-light;
    display: inline-block;
    padding: 14px 40px 14px 20px;
    margin: 20px 10px 0 10px; 
    border-radius: 5px;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.15;
    color: $color-dark;
    &:hover{
        text-decoration: none;
        color: $color-dark;
        .vlastnosti-povolani_close{
            transform: rotate(180deg);
        }
    }
}
.vlastnosti-povolani_close{
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    
    transition: transform .5s ease-in-out;
}