$color-white: #fff;
$color-black: #000;

$color-dark: #333333;
$color-blue: #00E0FF;
$color-light: #E4FCFF;
$color-inactive: #D5D5D5;
$color-hover: #00BAAF;
$color-button: #00CABE;

$color-gradient-1: #00E0FF;
$color-gradient-2: #AD6DFF;

@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/abstracts/_colors.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/abstracts/_em.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/abstracts/_fonts.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/abstracts/_variables.scss";
@import "./../../bootstrap/bootstrap.scss";
@import "./../../breakpoints";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/base/_base.scss";
//@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_1-1-podklady.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_1-homepage.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_11-final.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_2-rozcestnik.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_3-vlastnosti.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_4-kartapovolani.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_4-vsechnyprofese.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_9-analyza.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_aside.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_footer.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_header.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_main.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/layout/_navigation-bar.scss";
//
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/components/_buttons.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/components/_forms.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/components/_icons.scss";
@import "C:/wamp64/www/multimujzivotposkole/resources/src/scss/components/_stars.scss";  

/** FILE DATA **/
.hp-container{
    min-height: 100vh; 
    width: 100%;
    position: relative;
    @include screen-min-sm{
        min-height: 881px;
    }
    @include screen-xs-sm{
        height: 100%;
        min-height: 767px;
    }
    .container, .row, .hp-body{
        height: 100%;
    }
    .homepage-footer{
        background: #333;
        position: absolute;
        bottom: 0;
    }
    .hp-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .hp-bg{
        background-image: url('./../img/rozcestnik-kraje.png'); 
        height: 100%; 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        & .container > .row > .col{
            @include screen-xs-sm{
               padding-left: 0;
               padding-right: 0;
            }
        }
        a{
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 1.25;
            color: $color-dark;

            @include screen-xs-sm{
                font-size: 18px;
            }
        }
        .first-link{
            top: 102px;
            left: 369px;
            position: absolute;
            transform: rotate(-8deg);

            @include screen-lg-xl{
                left: 284px;
            }
        
            @include screen-md-lg{
                left: 163px;
            }
        
            @include screen-sm-md{
                left: 97px;
            }
        
            @include screen-xs-sm{
                top: 79px;
                left: 67px;
            }
        }
        .second-link{
            top: 258px;
            left: 406px;
            position: absolute;

            @include screen-lg-xl{
                left: 320px;
            }
        
            @include screen-md-lg{
                left: 195px;
            }
        
            @include screen-sm-md{
                left: 135px;
            }

            @include screen-xs-sm{
                top: 161px;
                left: 85px;
            }
        }
        .third-link{
            top: 400px;
            left: 376px;
            position: absolute;
            color: $color-white;

            @include screen-lg-xl{
                left: 290px;
            }
        
            @include screen-md-lg{
                left: 165px;
            }
        
            @include screen-sm-md{
                left: 100px;
            }

            @include screen-xs-sm{
                top: 236px;
                left: 65px;
            }
        }
        .fourth-link{
            top: 558px;
            left: 426px;
            transform: rotate(15deg);
            position: absolute;
            color: $color-white;

            @include screen-lg-xl{
                top: 560px;
                left: 340px;
            }
        
            @include screen-md-lg{
                left: 215px;
            }
        
            @include screen-sm-md{
                top: 562px;
                left: 165px;
            }

            @include screen-xs-sm{
                top: 321px;
                left: 105px;
            }
        }
    }
    .hp-h2{
        margin-top: 42px;
        margin-bottom: 19px;
        font-family: 'Indie Flower';
        font-style: normal;
        font-weight: normal;
        font-size: 80px;
        line-height: 1;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: $color-dark;

        @include screen-lg-xl{
            font-size: 80px;
        }
    
        @include screen-md-lg{
            font-size: 80px;
        }
    
        @include screen-sm-md{
            font-size: 70px;
        }
    
        @include screen-xs-sm{
            font-size: 50px;
        }
    }
    .hp-h3{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 1.25;

        @include screen-lg-xl{
            font-size: 35px;
        }
    
        @include screen-md-lg{
            font-size: 35px;
        }
    
        @include screen-sm-md{
            font-size: 25px;
        }
    
        @include screen-xs-sm{
            font-size: 25px;
        }
    }
    .hp-content{
        position: relative;
    }
    .hp-sloup{
        margin: 41px auto;
        width: 558px;
        height: 1100px;
        background-image: url('./../img/rozcestnik-sloup.svg');
        background-position: top;
        background-repeat: no-repeat;

        @include screen-xs-sm{
            max-width: 100%;
            width: 320px;
            height: 680px;
            background-size: 300px 650px;
            background-position: center bottom;
            margin: 0 auto;
            

            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0; 
        }

        @include screen-max-sm{
            bottom: -70px;
        }
    }
    .hp-nckp{
        width: 202px;  //1.104
        height: 223px;
        background-image: url('./../img/nckp2020.svg');
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 700px;
        margin-left: -10px;

        @include screen-sm-md{
            margin-left: 19px;
        }
        @include screen-max-sm{
            top: 390px;
            width: 108px;
            height: 119px;
            background-size: contain;
            margin-left: -6px;
        }
    }
} 