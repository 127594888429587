/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.navigation-bar{
    height: 126px;
    position: fixed; 
    bottom: 0px;
    width: 100%;
    background: $color-white;
    z-index: 100;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

    @include screen-max-md{
        height: 130px;
    }

    &--plus{
        height: 135px !important;
    }

    &--dynamic{
        height: auto !important;
    }
}
.navigation-bar-text{
    font-size: 16px;

    @include screen-max-md{
        font-size: 14px;
        text-align: center !important;
    }
}