/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.homepage-header{
    height: 100px;
    img{
        max-height: 100px;
    }

    @include screen-sm-md{
        height: 90px;
    }
    @include screen-xs-sm{
        height: 90px;

        img{
            padding-top: 15px;
            max-height: 90px;
        }
    }
}
.homepage{
    background-image: url('./../img/rectangle.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%; 
    padding-top: 50px;
    padding-bottom: 44px;
    color: $color-white;

    .perex{
        font-weight: 800;
        font-size: 22px;
        line-height: 27px;

        @include screen-md-lg{
            font-size: 18px;
            line-height: 22px;
        }

        @include screen-max-md{
            font-size: 16px;
            line-height: 20px;
        }
    }
}
.h2-homepage{
    color: $color-white;
    font-family: 'Indie Flower';
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 150px;
    margin-bottom: 50px;

    @include screen-lg-xl{
        font-size: 80px;
        line-height: 120px;
    }

    @include screen-md-lg{
        font-size: 80px;
        line-height: 120px;
    }

    @include screen-sm-md{
        font-size: 70px;
        line-height: 100px;
    }

    @include screen-xs-sm{
        font-size: 50px;
        line-height: 70px;
    }
}
.homepage-video-container{
    margin-bottom: 60px;
}
.homepage-video{
    position: relative;
    padding-bottom: 56.2%;
    width: 100%;
    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
.homepage__logos{
    img{
        max-height: 49px;
        max-width: 100%;
        margin: 0 auto;

        @include screen-lg-xl{
            max-height: 39px;
        }

        @include screen-max-lg{
            max-height: 29px;
        }
    }
}
.homepage-logo{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center; /*space-between;*/
    align-items: center;
    flex-wrap: wrap;

    li{
        list-style: none;

        @include screen-max-md{
            margin-bottom: 32px;
        }
    }
}

.homepage-footer{
    background: $color-dark;
    color: $color-white;
}