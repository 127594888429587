html, body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    min-height:100%;
}
body{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    margin: 0;
    padding: 0;
    overflow: visible;
}
.preload * {
    transition: none !important;
}
.box-shadow{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.gradient-line{
    height: 5px;
    background: linear-gradient(135deg, $color-gradient-1 0%, $color-gradient-2 100%);
}
.page-bottom-space{
    margin-bottom: 200px;
}
.page-bottom-space-half{
    margin-bottom: 100px;
}
.page-space{
    height: 150px;
    margin-top: 10px;
    margin-bottom: 50px; 
}
.contrast{
    color: $color-button;
    font-size: 1.4rem;
    font-weight: bold; 
}
.boxshadow{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.error{
    border: 1px solid #b8272b; 
    color: #b8272b; 
    padding: 5px;
}
.custom-select, .flexdatalist-alias{
    border: 2px solid $color-button !important; 
}
.custom-textarea{
    border: 2px solid $color-button !important; 
    width: 100%;
    padding: 6px 12px;
}
input:focus, textarea:focus, select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.drobeckova_navigace{
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;

    @include screen-max-lg{ 
        visibility: hidden;
        display: none;
    }

    & a:hover{
        text-decoration: none;
        color: $color-button;
    } 
}
.drobeckova_polozka{
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: -15px;
    width: 138px;
    height: 65px;
    align-items: center;
    display: flex;
    justify-content: center;

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    &--disabled{
        background: $color-inactive;
        color: $color-white;
        box-shadow: none; 
    }
    &--first{
        color: $color-button;
        border: 2px solid $color-button;

    }
    &--active{
        background: $color-button;
        color: $color-white;
    }
    &--first{
        padding-left: 25px;
        position: relative;
    
        & .fac{
            position: absolute;
            left: 15px;
            top: 20px;
        }
    }
    &:not(.drobeckova_polozka--disabled):not(.drobeckova_polozka--active):hover {
        background: $color-hover; 
  
        & > a{
            color: $color-white; 
        }

        & .fac-zpet{
            background: url('./../img/icons/zpet-active.svg'); 
        }
    }
    @include screen-lg-xl{
        font-size: 16px;
        line-height: 19px;
        width: 119px;
    }
    @include screen-md-lg{
        font-size: 12px;
        line-height: 16px;
        width: 115px;
    }
}
[class*='col'] {
    position: static !important;
}
.absolute{
    position: absolute; 
    z-index: 99;
    width: 100%;
    left: 0;
}
.list-container{
    position: relative;
}
.sipka{
    height: 40px;
    width: 40px;
    background: #FFF;
    position: absolute;
    //margin-top: 29px;
    margin-top: 1.8325rem;
    left: 45%;
    transform: matrix(0.71, -0.74, 0.68, 0.74, 0, 0);
    z-index: 10;
    outline: 1px solid transparent;
}
.navigation-bar .row{
    @include screen-max-sm{
        padding-left: 5px;
        padding-right: 5px;  
    }
}
.container-sub{  
    z-index: 1;
    top: 100%;

    & input[type=radio]:checked + label { 
        font-weight: bold !important; 
        color: $color-hover !important;
    }
    & .subselect[type=radio]:checked + label { 
        background: $color-white !important;
        color: $color-button !important;
    }  
    & .subselect[type=radio]:hover{
        border: 1px solid $color-hover;
    } 

    & .custom-control-label::before{
        border: $color-hover solid 1px;
    }

    & .custom-control-label:hover{ 
        font-weight: bold !important; 
        color: $color-hover !important;
    } 

    & .custom-control-label{
        cursor: pointer; 
    }
}
.povolani-sub-box{
    background: $color-white;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

.green-color{
    color: $color-hover;
}

.popover{
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.arrow::before{
    border: 0 !important;
}
.popover-body{
    padding: 25px;
    font-size: 18px;
    line-height: 27px;
} 
/*
.popover{ 
    border: 0 !important;
    border-radius: 0 !important;
    top: 40px !important;
    left: 20px !important;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.arrow{
    top: 0 !important;
}
.arrow::before{
    border: 0 !important;
}
.bs-popover-right .arrow::after{
    left: -15px;
    top: -5px;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after{
    border-width: 25px 25px 25px 0 !important;
}
.popover-body{
    padding: 25px;
    font-size: 18px;
    line-height: 27px;
}
*/

/** 3.0 VYBER POVOLANI **/
.povolani-box{
    min-height: 300px;
    margin-top: 70px;
    position: relative;
}
.povolani-header{
    height: 170px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center; 
}
.povolani-header-background{
    width: 170px;
    height: 170px;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    background: $color-light;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
    left: 0; 
    right: 0; 
    top: 0;
    margin-left: auto; 
    margin-right: auto; 
}
.povolani-header-background--bigger{
    width: 200px !important;;
    height: 200px !important;
    transition: all 1s ease;
}
.povolani-header-background--selected{
    width: 170px;
    height: 170px;
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    background: $color-button;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
    left: 0; 
    right: 0; 
    top: 0;
    margin-left: auto; 
    margin-right: auto; 
}
.povolani-header--image{
    margin: auto;
}
.povolani-main{
    height: 60px;
    margin-bottom: 10px;
}
.povolani-price{
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 20px;
}
.povolani-footer{
    text-align: center;
}
.seznam-container{
     
    & input[type=radio]
    {
        visibility: hidden;
        display: none;
    } 
    & input[type=radio]:checked + label { 
        background: $color-button;
        color: $color-white;
    }
}
/** 3.0 VLASTNOSTI **/ 
.container--wide{
    @include screen-min-xl{
        max-width: 1440px;
    }
}
.vlastnosti-container{
    .vlastnosti-row{
        margin-bottom: 100px;
        h3{
            @include screen-min-lg{
                max-width: 250px;
            }
        }
        .vlastnosti--image-column{
            @include screen-min-xl{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .vlastnosti-image{
        margin: 20px auto;
    }
    & > :first-child{
        .vlastnosti-image{
            margin-top: -32px;

            @include screen-lg-xl{
                margin-top: 0;
            }
        }
    }
    .vlastnosti-box{
        display: inline-block;
        padding: 15px 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        margin: 12px 20px 12px 0;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer; 
        position: relative;
        &--smaller{
            font-size: 16px;
        }
        &--line{
            border: 2px solid $color-button;
            background: $color-white; 
        }
        &--full{
            background: $color-button;
            color: $color-white;
        }
        &--inline{
            font-variant-numeric: lining-nums; 
        }
        & label{
            display: block;
            margin: 0; 
        }
        & .fac-quest{
            background: url("/img/icons/q-white.svg");
            position: absolute;
            right: -12px;
            top: -12px;
        }
    }

    .no-pointer{
        cursor: auto; 
    }

    & input[type=checkbox]
    {
        visibility: hidden;
        display: none;
    } 
    & input[type=checkbox]:checked + label { 
        background: $color-button !important;
        color: $color-white !important;
        /*font-weight: bold;*/
        /*text-shadow: 1px 0 0 #FFF;*/
    }  
}
/** 3.0 SEZNAM PROFESI **/
.profese-list{

    & h4, & h3, .inline-heading {
        display: inline-block;
        margin-right: 60px;

        @include screen-max-md{
            width: 100%;
        }
    }
}
/** 3.0 VLASTNOSTI POVOLANI **/
.vlastnosti-povolani-box{
    background-image: url('./../img/vlastnostipovolani.png');
    background-repeat: no-repeat;
    background-size: cover;
    //background-position: center; 
    height: 100px;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 44px;
    color: $color-white;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);

    @include screen-md-lg{
        //height: 140px;
        font-size: 30px;
        line-height: 38px;
    }
    @include screen-sm-md{
        //height: 190px;
        font-size: 28px;
        line-height: 35px;
    }
    @include screen-xs-sm{
        font-size: 23px;
        line-height: 29px;
        //height: 190px;
        margin-bottom: 10px !important;
    }
}
.vlastnosti-popis{
    font-size: 0.9em; 
}
.wrap-row{
    @include screen-xs-sm{
        h4{
            width: 100%;
        } 
    }
}
/** 4.0 KARTA POVOLANI **/
.bold-link{
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
}
.karta-header{
    position: relative;
    display: flex;
    align-items: center; 
}
.karta-header-background{
    position: absolute;
    width: 360px;
    height: 360px;
    z-index: -1;
    border-radius: 50%;
    left: 0; 
    right: 0; 
    top: 0;
    margin-left: auto; 
    margin-right: auto; 
    background: $color-hover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @include screen-md-lg{
        width: 300px;
        height: 300px;
        margin: auto;
    }

    @include screen-max-420{
        width: 250px;
        height: 250px;
    }
}
.light-section{
    background: $color-light;
}
.mzda-space{
    margin-top: 0;

    @include screen-max-md{
        margin-top: 40px;
    }
}
#iframeplaceholder{
    width: 0px;
    height: 0px;
    display: none;
    visibility: hidden; 
}
/** 4.0 BYDLENI **/
.modal-lg, .modal-xl{
    max-width: 1108px;
}
.modal-body{
    max-width: 755px;
    margin: 0 auto;
}
.modal-body-max{
    max-width: 1024px !important;
    margin: 0 auto;
}
/** 9.0 ANALYZA **/
.analyze-table{
    font-size: 18px;
    line-height: 27px;

    & tr:first td{
        border: 0 !important;
    }

    & .header{
        font-weight: 800;
        font-size: 22px;
        line-height: 27px;
    }
    & .w-100{
        height: 1px;
        background: linear-gradient(135deg, #00E0FF 0%, #AD6DFF 100%);
    }
    & .col{
        padding-bottom: 10px;
        padding-top: 10px;
    }
    & .analyze-footer{
        height: 4px;
    }

    & .without-line{
        height: 0 !important;
    }
}

.hand-container{
    margin-top: -50px;

    @include screen-max-md{
        margin-top: 30px;
    }
}
/** 11.0 FINAL **/
.bye{
    background-image: url('./../img/bye.png');
    background-repeat: no-repeat;
    height: 250px;
    width: 250px;
    @include screen-max-xl{
        display: none;
    }
}
.bubble{
    background-image: url('./../img/icons/bubble.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 384px;
    width: 799px;
    padding: 80px;
    @include screen-max-xl{
        height: auto;
        width: 100%;
        padding: 50px;
        background-image: none;
        background-color: #E4FCFF;
        border-radius: 16px;
    } 
    @include screen-max-md{
        padding: 30px 12px 20px 12px;
    } 
    &-text{
        text-align: center;
        color: $color-black;
        width: 520px;
        @include screen-max-xl{
            width: 100%;
        }
        p{
            @include screen-max-xl{
                font-size: 16px;
            } 
        }
    }
}
.finalpage{
    background-image: url('./../img/rectangle.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height:100vh;
    width: 100%;
    color: $color-white;    
}
.finalpage-footer{
    background: $color-dark;
    color: $color-white;
    vertical-align: bottom;
    margin-top: -102px;
    height: 102px;
    position: relative;
    z-index: 5;
}
.kartapovolani-background{
    background-image: url('./../img/kniha2.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 420px;
    min-height: 380px; 

    @include screen-lg-xl{
        background-size: 350px;
    } 

    @include screen-max-lg{
        background-size: 300px;
        padding-bottom: 200px !important;
        background-position: center bottom; 
    } 
}
.link-list{
    margin-top: 5px;
    list-style: none;
    padding-left: 0;

    & li{
        margin-bottom: 15px;
    }

    & a{
        font-size: 18px;
        line-height: 27px;
        text-decoration-line: underline;
    }
}
/** 8.0 - rezerva **/
.rezerva-header-background{
    position: absolute;
    width: 180px;
    height: 180px;
    z-index: -1;
    border-radius: 50%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: $color-light;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @include screen-md-lg{
        width: 180px;
        height: 180px;
        margin: auto;
    }

    @include screen-max-420{
        width: 180px;
        height: 180px;
    }
}
.hide_absolute{
    //display: none;
    visibility: hidden;
}
.show_absolute{
    visibility: visible;
    //display: block;
}
/** 11.0 - hotovo **/
.complete{
    background-image: url('./../img/rectangle.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 120px;
    color: $color-white;
    min-height: 745px;
    height: calc(100vh - 102px);
}

/* slider */
.ui-widget.ui-widget-content {
    border: 1px solid #000 !important;
}
.ui-slider-horizontal {
    height: 2px !important;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
    background: $color-hover !important;
    border: 1px solid $color-hover !important;
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover{
    background: $color-hover !important;
}
.ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focuse{
    background: $color-hover !important;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, .ui-button:active, a.ui-button:active, .ui-button.ui-state-active:hover{
    border: 1px solid $color-hover !important;
}
.ui-slider .ui-slider-handle{
    margin-top: -3px !important;
    border-radius: 50% !important;
} 

/** input number **/
.input-number-group {
  display: flex;
  /*justify-content: center;*/

  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    appearance: none;
  }
  .input-group-button {
    line-height: 30px;
  }
  .input-number {
    width: 35px;
    padding: 0 3px;
    text-align: center;
    outline: none;
    display: block;
    margin: 0;
    background: $color-hover;
    color: $color-white;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
  }
  .input-number, .input-number-decrement, .input-number-increment {
    height: 30px;
    user-select: none;
    border-radius: 2px;
    border: 0;
  }
  .input-number--zero{
    background: $color-white;
    color: $color-dark;
    border: 1px solid $color-hover !important;
    font-weight: normal;
 }
 .input-number-decrement, .input-number-increment {
    display: inline-block;
    width: 30px;
    background: $color-white;
    color: $color-dark;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 32px;
    font-weight: 400;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

    &:hover{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }
  .input-number-decrement {
    margin-left: 5px;
  }
  .input-number-increment {
    margin-right: 5px;
  }

  .number_text{
    margin-left: 10px;
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}