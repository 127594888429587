.final{
    &-space{
        &_53{
            margin-bottom: 53px;
        }
        &_75{
            margin-bottom: 75px;
        }
    }
    &-container{
        padding-bottom: 400px;
        position: relative;
        @include screen-max-xl{
            padding-bottom: 120px;
        } 
    }
    &-sidebar{
        position: absolute;
        top: -270px;
        right: 48px;
        text-align: center;
        @include screen-max-xl{
            position: relative;
            top: auto;
            right: auto;
        } 
    }
    &-nextway{
        position: relative;
        margin: -32px auto 0 auto; 
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        @include screen-max-xl{
            margin: 0 auto; 
            width: auto;
        }
        &_button{
            position: absolute;
            bottom: 16px;
            left: 50%;
            transform: translateX(-50%);
        }
        &_line{
            position: absolute;
            top: -5px;
            right: 0;
            @include screen-max-xl{
                top: 8px;
                height: 340px;
                width: auto;
            } 
            @include screen-max-md{
                height: 240px;
                width: auto;
            }
            @include screen-max-sm{
                height: 260px;
                max-height: calc(100% - 70px);
                width: auto;
            } 
            & > img{
                height: 100% !important;
                width: auto !important;
            }
        }
        &_container{
            position: relative;
        }
    }
}