.profese-list-search-container{
    height: 50px;
    border: 2px solid $color-button;
    border-radius: 5px;
    display: flex;
    flex-direction: row;

    input[type="text"]{
        width: 100%;
        border: 0;
        padding-left: 36px;
        font-size: 18px;
        line-height: 1.5;
        color: $color-dark;
    }
    input[type="text"]::placeholder {
        opacity: 1;
        color: $color-dark;
    }
    button{
        border: 0;

        &:hover{
            background-color: transparent;
        }
    }
}