/*
  RESPONSIVE BREAKPOINTS:
  -----------------------
  desktop: 1170px
  tablet: 768px - 1169px
  tablet landscape: 992px - 1199px
  tablet portrait: 768px - 991px
  mobile: 0px - 767px
  mobile landscape: 576px - 767px
  mobile portrait: 0px - 575px


  desktop: 1170px
  tablet: 768px - 1169px
  tablet landscape: 992px - 1199px
  tablet portrait: 768px - 991px
  mobile: 0px - 767px
  mobile landscape: 576px - 767px
	mobile portrait: 0px - 575px
  
  
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+
 
  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/

//xl - ..
@mixin screen-min-xl {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    @content;
  }
}
//lg - xl
@mixin screen-lg-xl {
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 0.063em) {
    @content;
  }
}
//lg - ...
@mixin screen-min-lg {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
}
// md - lg
@mixin screen-md-lg {
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 0.063em) {
    @content;
  }
}
//md - ...
@mixin screen-min-md {
  @media (min-width: map-get($grid-breakpoints, md)) {
    @content;
  }
}
//sm - md
@mixin screen-sm-md {
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 0.063em) {
    @content;
  }
}
//sm - ...
@mixin screen-min-sm {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    @content;
  }
}
//xs - sm
@mixin screen-xs-sm {
  @media (max-width: map-get($grid-breakpoints, sm) - 0.063em) {
    @content;
  }
}


@mixin screen-max-sm {
  @media (max-width: map-get($grid-breakpoints, sm) - 0.063em) {
    @content;
  }
}
@mixin screen-max-md {
  @media (max-width: map-get($grid-breakpoints, md) - 0.063em) {
    @content;
  }
}
@mixin screen-max-lg {
  @media (max-width: map-get($grid-breakpoints, lg) - 0.063em) {
    @content;
  }
}
@mixin screen-max-xl {
  @media (max-width: map-get($grid-breakpoints, xl) - 0.063em) {
    @content;
  }
}




@mixin screen-max-420 {
  @media (max-width: 420px) {
    @content;
  }
}