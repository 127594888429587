//Baseline
$baseline: 1.5rem;

//Fonts
$base-font-size: 16px;

$spacer: 1rem !default;
$spacers: () !default;

$spacers: (
    // The rest of numbers you need to use...
    6: ($spacer * 4)
);