.fac{
    vertical-align: middle;
    display: inline-block;
}
.fac-plane{
    background: url('./../img/icons/plane.svg');
    background-repeat: no-repeat;
    background-size: 20px 15px;
    width: 20px;
    height: 15px;
    color: #FFF;
    margin-right: 10px;
}
.fac-zarovka{
    background: url('./../img/icons/zarovka.svg');
    background-repeat: no-repeat;
    background-size: 13px 18px;
    width: 13px;
    height: 18px;
    color: #FFF;
    margin-bottom: 3px;
    margin-right: 10px;
}
.fac-list{
    background: url('./../img/icons/bullet-list.svg');
    background-repeat: no-repeat;
    background-size: 20px 18px;
    width: 22px;
    height: 20px;
    color: #FFF;
    margin-bottom: 3px;
    margin-right: 10px;
}
.fac-otaznik{
    background: url('./../img/icons/otaznik.svg');
    background-repeat: no-repeat;
    background-size: 14px 18px;
    width: 14px;
    height: 18px;
    color: #FFF;
    margin-bottom: 2px;
    margin-right: 10px;
}
.fac-ex{
    background: url('./../img/icons/mark.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    color: #FFF;
    margin-bottom: 2px;
    margin-right: 10px;
    position: absolute;
}
.fac-zpet{
    background: url('./../img/icons/zpet.svg');
    background-repeat: no-repeat;
    background-size: 8px 13px;
    width: 8px;
    height: 13px;
    color: #FFF;
    margin-bottom: 2px;
    margin-right: 10px;
}
.fac-vpred{
    background: url('./../img/icons/vpred.svg');
    background-repeat: no-repeat;
    background-size: 8px 13px;
    width: 8px;
    height: 13px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 10px;
}
.fac-down{
    background: url('./../img/icons/down.svg');
    background-repeat: no-repeat;
    background-size: 20px 11px;
    width: 20px;
    height: 11px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 5px !important;
}
.fac-pdf{
    background: url('./../img/icons/pdf.svg');
    background-repeat: no-repeat;
    background-size: 25px 23px;
    width: 25px;
    height: 23px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 10px;
}
.fac-printer{
    background: url('./../img/icons/printer.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 10px;
}
.fac-print{
    background: url('./../img/icons/printer.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 10px;
}
.fac-quest{
    background: url('./../img/icons/q.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    color: #FFF;
    margin-bottom: 2px;
    margin-left: 10px;
}
.fac-close{
    background: url('./../img/icons/close.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    color: #FFF;
    margin-right: 15px;
    margin-top: 10px;
}
.fac-piggy{
    background: url('./../img/icons/piggy-bank.svg');
    background-repeat: no-repeat;
    background-size: 25px 22px;
    width: 25px;
    height: 22px;
    color: #FFF;
    margin-right: 15px;
    margin-bottom: 5px;
}
.fac-2x{
    background-size: 35px 25px;
    width: 35px;
    height: 25px;
    margin-right: 20px;
}
.fac-hover{
    cursor: pointer;
}
.fac-search{
    background: url('./../img/icons/search-button.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    color: #FFF;
    margin-right: 20px;
}
.fac-filter{
    background: url('./../img/icons/filter.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    color: #FFF;
    margin-bottom: 2px;
    margin-right: 10px;
}
.fac-filter_close{
    background: url('./../img/icons/filter_close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
}