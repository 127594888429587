.uplatneni-container{
    margin-bottom: 22px;

    h4{
        margin-top: 4px;
    }
}
.graf-uplatneni{
    height: 100%;
    position: relative;

    h5{
        margin-bottom: 11px;
        margin-top: 13px;
    }
    p{
        font-size: 16px;
        line-height: 1.5;
    }
    img{
        @include screen-min-xl{
            max-height: 208px;
            bottom: 13px;
            left: 0;
            position: absolute;
        }
        @include screen-lg-xl{
            max-height: 160px;
            bottom: 13px;
            left: 0;
            position: absolute;
        }
    }
}
.povolani-info{
    padding-left: 44px;
    position: relative;
    > i {
        position: absolute;
        top: 0;
        left: 0;
    }
}
.povolani-info-text{
    margin-top: 28px;
}