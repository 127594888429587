.stars {
  background: url("/img/star-line.png") repeat-x 0 0;
  width: 220px;
  margin: 0 auto;
}
.stars input[type="radio"] {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.stars input[type="radio"].star-5:checked ~ span { width: 100%; }
.stars input[type="radio"].star-4:checked ~ span { width: 80%; }
.stars input[type="radio"].star-3:checked ~ span { width: 60%; }
.stars input[type="radio"].star-2:checked ~ span { width: 40%; }
.stars input[type="radio"].star-1:checked ~ span { width: 20%; }

.stars label {
  display: block;
  width: 44px;
  height: 36px;
  margin: 0!important;
  padding: 0!important;
  text-indent: -999em;
  float: left;
  position: relative;
  z-index: 10;
  background: transparent!important;
  cursor: pointer;
}

.stars label:hover ~ span { background-position: 0 0; }
.stars label.star-5:hover ~ span { width: 100% !important; }
.stars label.star-4:hover ~ span { width: 80% !important; }
.stars label.star-3:hover ~ span { width: 60% !important; }
.stars label.star-2:hover ~ span { width: 40% !important; }
.stars label.star-1:hover ~ span { width: 20% !important; }

.stars span {
  display: block;
  width: 0;
  position: relative;
  top: 0;
  left: 0;
  height: 36px;
  background: url("/img/star-full.png") repeat-x 0 0;
  -webkit-transition: -webkit-width 0.5s;
  -moz-transition: -moz-width 0.5s;
  -ms-transition: -ms-width 0.5s;
  -o-transition: -o-width 0.5s;
  transition: width 0.5s;
}