/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.btn-custom-line{
    background: $color-white;
    border: 2px solid $color-button;
    color: $color-button;
    box-sizing: border-box;
    border-radius: 100px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:hover{
        background: $color-button;
        color: $color-white;

        & > .fac-zpet{
            background: url('./../img/icons/zpet-active.svg');
        }
    }

    @include screen-md-lg{
        font-size: 16px;
        line-height: 19px;
    }
    @include screen-xs-sm{
        font-size: 14px;
        line-height: 17px;
    }
}
.btn-custom-line--selected{
    background: $color-button;
    border: 2px solid $color-button;
    color: $color-white;
    box-sizing: border-box;
    border-radius: 100px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:hover{
        background: $color-button;
        color: $color-white;
    }
}

.btn-custom-video{
    border-bottom: 1.5px solid $color-white;
    color: $color-white;
    box-sizing: border-box;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
    transition: border 0.3s;
    position: relative;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0;
    display: inline;

    &:hover{
        color: $color-white;
        border-bottom: 1.5px solid transparent;
    }

    @include screen-lg-xl{
        font-size: 24px;
    }

    @include screen-md-lg{
        font-size: 24px;
    }

    @include screen-sm-md{
        font-size: 20px;
    }

    @include screen-xs-sm{
        font-size: 20px;
    }
}

.btn-custom--full{
    background: $color-button;
    border: 2px solid $color-button;
    color: $color-white;
    box-sizing: border-box;
    border-radius: 100px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:hover{
        background: $color-button;
        color: $color-white;
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.35);
    }

    @include screen-md-lg{
        font-size: 16px;
        line-height: 19px;
    }
    @include screen-xs-sm{
        font-size: 14px;
        line-height: 17px;
    }
}
.continue:disabled{
    background: $color-inactive;
    color: $color-white;
    border: 2px solid $color-inactive;
    box-shadow: none; 
}
.btn--disabled{
    background: $color-inactive;
    color: $color-white;
}
.btn--small{
    padding: 7px 30px;
}
.btn--wide{
    padding: 7px 35px;
}
.btn--filter{
    padding: 13px 28px;
}
.btn--normal{
    padding: 20px 30px;

    @include screen-md-lg{
        padding: 20px 25px;
    }
    @include screen-xs-sm{
        padding: 20px 20px;
    }
}
.btn--wide-normal{
    padding: 15px 35px;

    @include screen-xs-sm{
        padding: 13px 20px;
    }
}
.btn--extrawide-normal{
    padding: 15px 60px;
}
.btn--bigger-normal{
    padding: 21px 30px;
}
.btn--homepage{
    font-size: 35px;
    line-height: 44px;
    padding: 15px 50px;

    @include screen-max-sm{
        font-size: 22px;
        line-height: 27px;
        padding: 12px 20px;
    }

    @include screen-sm-md{
        font-size: 30px;
        line-height: 37px;
        padding: 15px 40px;
    }
}
.btn--link{
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;

    /* identical to box height, or 150% */
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;

    /* White */
    color: $color-white;

    &:hover{
        color: $color-white;
        text-decoration: none;
    }
}
button{
    &:focus{
        outline: none;
    }
}