/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
html {
	scroll-behavior: smooth;
	&.js-baseline {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(rgba(255, 0, 0, 0.25) 1px, transparent 1px);
			background-size: auto ($baseline / 2);
			z-index: 9997;
		}
		&::after {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(rgb(11, 226, 215) 1px, transparent 1px);
			background-size: auto $baseline;
			z-index: 9998;
		}
	}
}
.baseline-toggle {
	display: inline-block;
	line-height: $baseline * 2;
	font-size: 15px;
	font-weight: 600;
	padding: 0 13px;
	border: 2px solid $color-black;
	position: fixed;
	bottom: 0;
	right: 0;
	cursor: pointer;
	background: $color-white;
	user-select: none;
	z-index: 9999;
}
img {
	display: block;
	-ms-interpolation-mode: bicubic;
	opacity: 1;
	transition: opacity 0.8s ease;
	height: auto;

	&[data-src] {
		opacity: 0;
	}
}
h1,.like-h1{
	font-style: normal;
	font-weight: 800;
	font-size: 54px;
	line-height: 63px;
	font-feature-settings: 'pnum' on, 'lnum' on;

	@include screen-lg-xl{
		font-size: 48px;
		line-height: 60px;
	}

	@include screen-md-lg{
		font-size: 40px;
		line-height: 50px;
	}

	@include screen-max-md{
		font-size: 35px;
		line-height: 44px;
	}
}
h2,.like-h2{
	font-style: normal;
	font-weight: 800;
	font-size: 44px;
	line-height: 55px;
	font-feature-settings: 'pnum' on, 'lnum' on;

	@include screen-lg-xl{
		font-size: 35px;
		line-height: 44px;
	}

	@include screen-md-lg{
		font-size: 30px;
		line-height: 38px;
	}

	@include screen-max-md{
		font-size: 27px;
		line-height: 34px;
	}
}
h3,.like-h3{
	font-style: normal;
	font-weight: 800;
	font-size: 35px;
	line-height: 44px;
	font-feature-settings: 'pnum' on, 'lnum' on;

	&--regular{
		font-style: normal;
		font-weight: normal;
	}

	@include screen-lg-xl{
		font-size: 30px;
		line-height: 38px;
	}

	@include screen-md-lg{
		font-size: 27px;
		line-height: 34px;
	}

	@include screen-max-md{
		font-size: 25px;
		line-height: 32px;
	}
}
h4,.like-h4{
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	line-height: 42px;
	font-feature-settings: 'pnum' on, 'lnum' on;

	@include screen-lg-xl{
		font-size: 26px;
		line-height: 33px;
	}

	@include screen-md-lg{
		font-size: 24px;
		line-height: 30px;
	}

	@include screen-max-md{
		font-size: 22px;
		line-height: 28px;
	}
}
h5,.like-h5{
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 27px;
	font-feature-settings: 'pnum' on, 'lnum' on;

	@include screen-lg-xl{
		font-size: 20px;
		line-height: 25px;
	}

	@include screen-md-lg{
		font-size: 20px;
		line-height: 25px;
	}

	@include screen-max-md{
		font-size: 18px;
		line-height: 23px;
	}
}
p{
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 27px;

	@include screen-max-sm{
		font-size: 16px;
		line-height: 24px;
    }
}
.link{
	color: $color-button;
	text-decoration: underline;
	background: transparent;
	border: 0;
	cursor: pointer;;

	&:hover{
		color: $color-hover;
		text-decoration: none;
	}
}
.mb--60{
	margin-bottom: -60px;
}
.mb-40{
	margin-bottom: 40px;
}
.mr-40{
	margin-right: 40px;
	@include screen-max-sm{
		margin-right: 0;
    }
}