/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.page-header{
    background-image: url('./../img/page-header.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 30px;
}
.select-box{
    min-height: 460px;
    position: relative;

    @include screen-lg-xl{
        min-height: 440px;
    }
    @include screen-md-lg{
        min-height: 647px;
    }
    @include screen-max-md{
        margin-bottom: 40px;
        min-height: 300px;
    }
    @include screen-xs-sm{
        margin-bottom: 40px;
        min-height: 320px;
    }

    &-first{
        h2{
            max-width: 400px;
        }
    }

    &__image--left{
        position: absolute;
        bottom: -15px;
        left: -156px;

        @include screen-max-xl{
            left: -66px;
            height: 313px;
        }

        @include screen-max-lg{
            position: relative;
            bottom: auto;
            left: auto;
            margin: 32px auto;
            height: 250px;
        }
    }

    &__image--right{
        position: absolute;
        bottom: -15px;
        right: -156px;

        @include screen-max-xl{
            right: -42px;
            height: 313px;
        }

        @include screen-max-lg{
            position: relative;
            bottom: auto;
            right: auto;
            margin: 147px auto 0 auto;
            height: 250px;
        }

        @include screen-max-md{
            position: relative;
            bottom: auto;
            right: auto;
            margin: 32px auto;
            height: 250px;
        }
    }

    .header-div{

        text-align: center;
        padding: 25px;
        margin: 25px;

        @include screen-md-lg{
            padding-left: 5px;
            padding-right: 5px;
            height: 130px;
        }
    }
}